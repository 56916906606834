import './bootstrap';
import '../sass/app.scss';
import 'remixicon/fonts/remixicon.css';

// Import modules...
import { createApp, h } from 'vue';
import { createPinia } from 'pinia';
import { createInertiaApp } from '@inertiajs/inertia-vue3';
import { InertiaProgress } from '@inertiajs/progress';
import { createGtm } from 'vue-gtm';
import VueCookies from 'vue3-cookies';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import tooltipDirective from './Directives/tooltipDirective';
import postHog from './Plugins/posthog';
import baseMixin from './base';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { all } from '@awesome.me/kit-9286fd9f41/icons'

/* add icons to the library */
library.add(...all)

const pinia = createPinia();
const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

let gtmOptions = {
    id: 'GTM-P978NC5',
    defer: false,
    compatibility: false,
    enabled: true,
    debug: true,
    loadScript: true,
    trackOnNextTick: false,
};

let cookieOptions = {
    expireTimes: '30d',
    path: '/',
    domain: '',
    secure: false,
    sameSite: 'None',
};

import axios from 'axios';

const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');
if (csrfToken) {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    console.log('CSRF token set:', csrfToken);
} else {
    console.error('CSRF token not found');
}

// Refresh the token periodically
function refreshCsrfToken() {
    axios.get('/csrf-token-refresh')
        .then(response => {
            const newToken = response.data.token;
            axios.defaults.headers.common['X-CSRF-TOKEN'] = newToken;
            document.querySelector('meta[name="csrf-token"]').setAttribute('content', newToken);
            console.log('CSRF token refreshed');
        })
        .catch(error => {
            console.error('Failed to refresh CSRF token', error);
        });
}

// Refresh token every 30 minutes
setInterval(refreshCsrfToken, 30 * 60 * 1000);

axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 419) {
            console.error('CSRF token mismatch detected on client side', {
                url: error.config.url,
                method: error.config.method,
                headers: error.config.headers,
            });
        }
        return Promise.reject(error);
    }
);

import { Inertia } from '@inertiajs/inertia'

Inertia.on('error', (event) => {
  if (event.detail.response && event.detail.response.status === 419) {
    console.error('CSRF token mismatch. Refreshing the page.');
    window.location.reload();
  }
});

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: name => {
        const pages = import.meta.glob('./Pages/**/*.vue');
        return pages[`./Pages/${name}.vue`]();
    },
    setup({ el, app, props, plugin }) {
        const vueApp = createApp({ render: () => h(app, props) });

        vueApp
            .use(pinia)
            .use(plugin)
            .use(createGtm(gtmOptions))
            .use(VueCookies, cookieOptions)
            .use(Toast)
            .use(postHog)
            .component('font-awesome-icon', FontAwesomeIcon)
            .directive('tooltip', tooltipDirective)
            .mixin(baseMixin)
            .mixin({ methods: { route } })
            .mount(el);
    },
});

InertiaProgress.init({ color: '#009EF7' });
